import React from "react";
import logo from "./logo.svg";
import "./App.css";

import { Offline, Online } from "react-detect-offline";

function App() {
  return (
    <div>
      <Online>
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
          </header>
        </div>
      </Online>
      <Offline>no connection</Offline>
    </div>
  );
}

export default App;
