import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import Tile from "./Tile";
import * as serviceWorker from "./serviceWorker";

// import firebase from "firebase";
// import config from "./config";``

// const app = firebase.initializeApp(config);

// const db = app.firestore();

ReactDOM.render(<App/>, document.getElementById("root"));
// ReactDOM.render(<Tile db={db} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
